<template>
    <div>
        <div
            class="flex-wrap gap-2.5 grid grid-cols-4 decor-list"
            :class="(windowSize < 768 ? (sizeDecor === 'small' ? 'cols-2 ' : 'cols-1 ') : '') + decorListClass"
        >
            <DecorCard
                v-for="decor in decors"
                :key="decor.id"
                :category-slug="('decor' in decor) ? decor.decor.category.slug : decor.category.slug"
                :decor="('decor' in decor) ? decor.decor : decor"
                :size="sizeDecor"
                v-model:date-from="dateStart"
                v-model:date-to="dateEnd"
            />
        </div>
    </div>
</template>

<script setup>

import DecorCard from "~/components/decor/DecorCard.vue";
import Icon from "~/components/ui/Icon.vue";

const props = defineProps({
    decors: {
        type: Array,
        default: []
    },
    size: {
        type: String,
        default: 'default'
    },
    decorListClass: {
        type: String,
        default: 'default'
    },
    dateFrom: {
        type: String,
        default: undefined,
    },
    dateTo: {
        type: String,
        default: undefined,
    },
});
const emit = defineEmits(['update:date-from', 'update:date-end'])
const dateStart = computed({
    get: () => props.dateFrom,
    set: (value) => {
        emit('update:date-from', value)
    }
})
const dateEnd = computed({
    get: () => props.dateTo,
    set: (value) => {
        emit('update:date-to', value)
    }
})

const { size } = props;
const sizeControl = ref('small')

const sizeDecor = computed(() => {
    if (windowSize.value < 768) return sizeControl.value
    return size
})

let windowSize = ref(1920);
provide("windowSize", windowSize);

function setWindowSize() {
    windowSize.value = window.innerWidth;
}
onMounted(() => {
    setWindowSize();
    window.addEventListener("resize", setWindowSize);
});
</script>
<style scoped lang="scss">
.decor-list {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @media (min-width: 2200px) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @media (min-width: 2700px) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    @media (max-width: 1675px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: 1275px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}
</style>
